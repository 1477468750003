import store from '~/store'
import Fuse from 'fuse.js'
import dayjs from 'dayjs'
import utils from '~/utilities/utils'

import exhibitionApi from '../../../api/exhibition'

function sortByDate(a, b) {
  return new Date(a.StartDateTime || a.value) - new Date(b.StartDateTime || b.value)
}

function sortByName(a, b) {
  return a.Name.localeCompare(b.Name, undefined, { numeric: true, sensitivity: 'base' })
}

function sortTagsbyName(a, b) {
  return a.localeCompare(b)
}

function sortByCompanyName(a, b) {
  return (a.CompanyAccountName || a.Name).localeCompare(b.CompanyAccountName || b.Name)
}

function shuffleArray(array) {
  if (array) {
    for (let i = array.length - 1; i > 0; i -= 1) { // TODO: check if for is really needed
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }
  }
}

const symposiaList = {
  namespaced: true,
  state: {
    list: null,
    tags: [],
    companies: [],
    days: [],
    daysF: [],
    companiesBySponsorType: {},
    result: [],
    loading: false,
    fuseJs: null,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
      state.fuseJs = new Fuse(state.list, {
        keys: [
          'Name',
          'CompanyAccountName',
          'Presentation.Title',
          'Presentation.SpeakerFirstname',
          'Presentation.SpeakerSurname',
          'Chairs.Firstname',
          'Chairs.Surname',
          'groups.Name',
        ],
        maxPatternLength: 32,
        minMatchCharLength: 3,
        threshold: 0.3,
      })
    },
    setTags(state, payload) {
      state.tags = payload
    },
    setCompanies(state, payload) {
      state.companies = payload
    },
    setDays(state, payload) {
      state.days = payload
    },
    setDaysF(state, payload) {
      state.daysF = payload
    },
    setCompaniesBySponsorType(state, payload) {
      state.companiesBySponsorType = payload
    },
    startLoading(state) {
      state.loading = true
    },
    stopLoading(state) {
      state.loading = false
    },
  },
  actions: {
    async load({
      commit,
      state,
    }, payload) {
      if (!state.loading && state.list == null) {
        commit('startLoading')
        const list = await exhibitionApi.getExhibitionSymposiaList()
        /* region dayfilter */
        const filterBySessionTypeF = payload
        let dayFilterByArrayF = list.filter((item) => {
          if (filterBySessionTypeF?.includes(item.SessionType)) {
            return item
          } if (filterBySessionTypeF?.includes(item.Id)) {
            return item
          }
          return null
        })

        const daysF = [{
          value: 'all',
          name: 'All Days',
        }]

        dayFilterByArrayF.sort(sortByDate)

        const groupByPropF = 'StartDateTime'
        const groupsSortF = []
        dayFilterByArrayF = dayFilterByArrayF.reduce((groupedSymposia, currentValue) => {
          let groupByValueF = currentValue[groupByPropF] || 'Other'
          const gSymposia = groupedSymposia
          if (groupByPropF === 'StartDateTime') {
            groupByValueF = dayjs(groupByValueF).format('DD MMMM YYYY')
          }

          let sortIdx = groupsSortF.indexOf(groupByValueF)
          if (sortIdx === -1) {
            groupsSortF.push(groupByValueF)
            sortIdx = groupsSortF.indexOf(groupByValueF)
          }

          if (!groupedSymposia[sortIdx]) {
            gSymposia[sortIdx] = {
              day: groupByValueF,
              symposia: [currentValue],
            }
          } else {
            gSymposia[sortIdx].symposia.push(currentValue)
          }
          return gSymposia
        }, [])

        // eslint-disable-next-line max-len
        for (let i = 0; i < dayFilterByArrayF.length; i += 1) { // TODO: check if for is really needed
          if (dayFilterByArrayF[i].day) {
            const dayObject = {
              value: dayFilterByArrayF[i].day,
              text: dayFilterByArrayF[i].day,
            }
            daysF.push(dayObject)
          }
        }
        const tags = []
        const companies = []
        const companiesBySponsorType = {}
        for (let i = 0; i < list.length; i += 1) { // TODO: check if for is really needed
          list[i].Tags = []
          if (list[i].CompanyAccountName && !companies.includes(list[i].CompanyAccountName)) {
            companies.push(list[i].CompanyAccountName)
            if (list[i].SponsorType) {
              const spType = list[i].SponsorType
              if (!Object.prototype.hasOwnProperty.call(companiesBySponsorType, spType)) {
                companiesBySponsorType[spType] = []
              }
              companiesBySponsorType[spType].push(list[i].CompanyAccountName)
            }

            companies.sort(sortTagsbyName)
          }

          if (Object.prototype.hasOwnProperty.call(list[i], 'groups') && list[i].groups) {
            for (let j = 0; j < list[i].groups.length; j += 1) { // TODO: check if for is really needed
              if (!tags.includes(list[i].groups[j].Name)) {
                tags.push(list[i].groups[j].Name)
              }
              list[i].Tags.push(list[i].groups[j].Name)
            }
            tags.sort(sortTagsbyName)
          }
        }

        const days = [...new Set(list.filter((session) => session.StartDateTime).map((session) => session.StartDateTime.substring(0, 10)))]

        const dayTags = days.map((date) => ({
          name: dayjs(date).format('dddd, DD MMMM'),
          value: date,
        }))
        commit('setCompaniesBySponsorType', companiesBySponsorType)
        commit('setCompanies', companies)
        commit('setTags', tags)
        commit('setList', list)
        commit('setDays', dayTags.sort(sortByDate))
        commit('setDaysF', daysF)
        commit('stopLoading')
      }
    },
    async search({
      commit,
      state,
      dispatch,
    }, payload) {
      if (state.list == null) {
        await dispatch('load')
      }

      let result = []

      if (payload && payload.text) {
        const options = {
          keys: [
            'Name',
            'CompanyAccountName',
            'Presentations.Title',
            'Presentations.SpeakerFirstname',
            'Presentations.SpeakerSurname',
            'Chairs.Firstname',
            'Chairs.Surname',
            'groups.Name',
          ],
          maxPatternLength: 32,
          minMatchCharLength: 2,
          threshold: 0,
          ignoreLocation: true,
        }

        const fuse = new Fuse(state.list, options)
        const fuseResult = fuse.search(payload.text)
        for (let i = 0, len = fuseResult.length; i < len; i += 1) { // TODO: check if for is really needed
          result.push(fuseResult[i].item)
        }
      } else {
        result = state.list
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'tags') && payload.tags && payload.tags.length > 0) {
        const { tags } = payload
        result = result.filter((item) => {
          for (let i = 0, len = tags.length; i < len; i += 1) { // TODO: check if for is really needed
            const tag = tags[i]
            if (!item.Tags.includes(tag)) {
              return false
            }
          }
          return true
        })
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'oneOfTags') && payload.oneOfTags && payload.oneOfTags.length > 0) {
        const tags = payload.oneOfTags
        result = result.filter((item) => {
          for (let i = 0, len = tags.length; i < len; i += 1) { // TODO: check if for is really needed
            const tag = tags[i]
            if (item.Tags.includes(tag)) {
              return true
            }
          }
          return false
        })
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'companies') && payload.companies && payload.companies.length > 0) {
        const { companies } = payload
        result = result.filter((item) => {
          for (let i = 0, len = companies.length; i < len; i += 1) { // TODO: check if for is really needed
            const company = companies[i]
            if (item.CompanyAccountName === company) {
              return true
            }
          }
          return false
        })
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'days') && payload?.days.length > 0) {
        const dayTagArr = payload.days.map((item) => item.value)
        result = result.filter((session) => dayTagArr.includes((session.StartDateTime || '').substring(0, 10)))
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'company') && payload.company) {
        const { company } = payload
        result = result.filter((item) => item.CompanyAccountName === company)
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'sortBy') && payload.sortBy && result?.length) {
        if (payload.sortBy === 'date') {
          result.sort(sortByDate)
        } else if (payload.sortBy === 'name') {
          result.sort(sortByName)
        } else if (payload.sortBy === 'random') {
          shuffleArray(result)
        } else if (payload.sortBy === 'companyname') {
          result.sort(sortByCompanyName)
        } else if (payload.sortBy === 'featuredDateSort') {
          if (payload.featuredId) {
            const featuredId = payload.featuredId
            const featuredArray = [...result].filter((item) => featuredId.includes(item.Id)).sort(sortByDate)
            const restArray = [...result].filter((item) => !featuredId.includes(item.Id)).sort(sortByDate)
            result = featuredArray.concat(restArray)
          } else {
            result.sort(sortByDate)
          }
        } else if (payload.sortBy === 'upcoming') {
            const {config} = store.getters['config/configForKey']('app_config')
            const eventTimezone = config[0]?.EventTimeZone || 'Europe/Berlin'
            const selectedTimezone = localStorage.getItem('selectedTimezone') || eventTimezone
            const arrLive = []
            const arrNotStarted = []
            const arrEnded = []
            const currentTime = dayjs().tz()
            let startTime = ''
            let endTime = ''

            result.forEach(x => {
              startTime = dayjs.tz(x.StartDateTime, selectedTimezone)
              endTime = dayjs.tz(x.EndDateTime, selectedTimezone)
              if (currentTime.isAfter(endTime)) {
                arrEnded.push(x)
              } else if (currentTime.isBetween(startTime, endTime)) {
                arrLive.push(x)
              } else {
                arrNotStarted.push(x)
              }
            })

            result = arrLive.sort(sortByDate).concat(...arrNotStarted.sort(sortByDate)).concat(...arrEnded.sort(sortByDate))
        }
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'typefilter') && payload.typefilter) {
        let tags = []
        let companies = []
        const companiesBySponsorType = {}

        const { typefilter } = payload
        if (typefilter.filterBy?.length > 0) {
          result = result?.filter((item) => {
            if (typefilter.filterBy.includes(item.SessionType)) {
              return item
            } if (typefilter.filterBy.includes(item.Id)) {
              return item
            }
            return null
          })
        }
        if (payload.dateTabs && typefilter.value) {
          result = result.filter((item) => {
            if (typefilter.value === dayjs(item.StartDateTime).format('YYYY-MM-DD')) {
              return item
            }
            return null
          })
        }

        for (let i = 0; i < result?.length; i += 1) { // TODO: check if for is really needed
          result[i].Tags = []
          if (result[i].CompanyAccountName && !companies.includes(result[i].CompanyAccountName)) {
            companies.push(result[i].CompanyAccountName)
            if (result[i].SponsorType) {
              const spType = result[i].SponsorType
              if (!Object.prototype.hasOwnProperty.call(companiesBySponsorType, spType)) {
                companiesBySponsorType[spType] = []
              }
              companiesBySponsorType[spType].push(result[i].CompanyAccountName)
            }

            companies.sort(sortTagsbyName)
          }

          if (Object.prototype.hasOwnProperty.call(result[i], 'groups') && result[i].groups) {
            for (let j = 0; j < result[i].groups.length; j += 1) { // TODO: check if for is really needed
              if (!tags.includes(result[i].groups[j].Name)) {
                tags.push(result[i].groups[j].Name)
              }
              result[i].Tags.push(result[i].groups[j].Name)
            }
            tags.sort(sortTagsbyName)
          }
        }

        if (typefilter.sortBy === 'date') {
          result.sort(sortByDate)

          const groupByProp = 'StartDateTime'
          const groupsSort = []
          result = result.reduce((groupedSymposia, currentValue) => {
            let groupByValue = currentValue[groupByProp] || 'Other'
            const gSymposia = groupedSymposia
            if (groupByProp === 'StartDateTime') {
              groupByValue = dayjs(groupByValue).format('DD MMMM YYYY')
            }

            let sortIdx = groupsSort.indexOf(groupByValue)
            if (sortIdx === -1) {
              groupsSort.push(groupByValue)
              sortIdx = groupsSort.indexOf(groupByValue)
            }

            if (!groupedSymposia[sortIdx]) {
              gSymposia[sortIdx] = {
                day: groupByValue,
                symposia: [currentValue],
              }
            } else {
              gSymposia[sortIdx].symposia.push(currentValue)
            }
            return gSymposia
          }, [])

          if (payload && Object.prototype.hasOwnProperty.call(payload, 'filterBy') && payload.filterBy) {
            tags = []
            companies = []

            const { filterBy } = payload

            if (filterBy !== 'all') {
              result = result.filter((item) => {
                if (filterBy == item.day) {
                  return item
                }
                return null
              })
            }

            for (let i = 0; i < result.length; i += 1) { // TODO: check if for is really needed
              for (let j = 0; j < result[i].symposia.length; j += 1) { // TODO: check if for is really needed
                result[i].symposia[j].Tags = []

                if (result[i].symposia[j].CompanyAccountName && !companies.includes(result[i].symposia[j].CompanyAccountName)) {
                  companies.push(result[i].symposia[j].CompanyAccountName)

                  companies.sort(sortTagsbyName)
                }
              }

              for (let j = 0; j < result[i].symposia.length; j += 1) { // TODO: check if for is really needed
                if (Object.prototype.hasOwnProperty.call(result[i].symposia[j], 'groups') && result[i].symposia[j].groups) {
                  for (let k = 0; k < result[i].symposia[j].groups.length; k += 1) { // TODO: check if for is really needed
                    if (!tags.includes(result[i].symposia[j].groups[k].Name)) {
                      tags.push(result[i].symposia[j].groups[k].Name)
                    }
                    result[i].symposia[j].Tags.push(result[i].symposia[j].groups[k].Name)
                  }
                  tags.sort(sortTagsbyName)
                }
              }
            }
          }
        }
        if(payload.reloadTags) {
          commit('setCompaniesBySponsorType', companiesBySponsorType)
          commit('setCompanies', companies)
          commit('setTags', tags)
        }

      }
      return result
    },
    async getSymposiasForId({
      commit,
      state,
      dispatch
    }, payload) {
        if (state.list == null) {
          await dispatch('load')
        }

        return state.list.filter((symposia) => symposia.CompanyAccountId === payload.id)
    }
  },
  getters: {
    list(state) {
      return state.list
    },
    tags(state) {
      return state.tags
    },
    companies(state) {
      return state.companies
    },
    days(state) {
      return state.days
    },
    daysF(state) {
      return state.daysF
    },
    companiesBySponsorType(state) {
      return state.companiesBySponsorType
    },
    loading(state) {
      return state.loading
    },
  },
}
export default symposiaList
