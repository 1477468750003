import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

export default {
  componentProps: {
    props: {
      startTime: {
        type: String,
        default: null,
      },
      endTime: {
        type: String,
        default: null,
      },
    },
    computed: {
      ...mapGetters({
        superUser: 'instancesState/getSuperUser',
      }),
      componentIsActive() {
        if (this.superUser) {
          return true
        }
        return this.checkIsComponentTimeActive(this)
      },
    },
  },
  method: {
    methods: {
      checkIsComponentTimeActive(item) {
        let active = false
        if (item.startTime || item.endTime) {
          const currentTime = dayjs()
          const startTime = dayjs(item.startTime)
          const endTime = dayjs(item.endTime)
          if ((item.startTime && item.endTime) && currentTime.isBetween(startTime, endTime)) {
            active = true
          } else if ((item.startTime && !item.endTime) && currentTime.isAfter(startTime)) {
            active = true
          } else if ((!item.startTime && item.endTime) && currentTime.isBefore(endTime)) {
            active = true
          }
        } else {
          active = true
        }
        return active
      },
    },
  },
}
