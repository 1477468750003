import cssVars from 'css-vars-ponyfill'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // load on demand
import timezone from 'dayjs/plugin/timezone' // load on demand

import Vue from 'vue'
import themeUtil from '../utilities/theme'
import store from '../store'
import themeApi from '../api/theme'

dayjs.extend(utc)
dayjs.extend(timezone)
export default async function ({ next }) {
  if (store.getters['instancesState/getThemeLoaded']) {
    next()
    return
  }

  await store.dispatch('config/loadConfig')
  const theme = store.getters['config/configForKey']('theme')
  const config = store.getters['config/configForKey']('app_config')
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  const eventTimezone = config.config[0].EventTimeZone

  if (eventTimezone) {
    dayjs.tz.setDefault(eventTimezone)
  } else {
    dayjs.tz.setDefault('Europe/Berlin')
  }
  const themeStyles = await themeApi.getThemeStyles(event, language)
  store.commit('instancesState/setThemesStyles', themeStyles)

  const styles = themeUtil.createStyle(theme.vars, '')
  store.commit('instancesState/setCssVars', theme.vars)

  // css variables
  let cssEl = ''
  cssEl = document.createElement('style')
  cssEl.type = 'text/css'
  cssEl.textContent = styles
  cssEl.setAttribute('data-theme', 'yes')
  document.head.appendChild(cssEl)
  cssVars({
    watch: true,
  })
  // custom event theme styles
  let cssEl2 = ''
  cssEl2 = document.createElement('style')
  cssEl2.type = 'text/css'
  cssEl2.textContent = themeStyles.customEventCss
  document.head.appendChild(cssEl2)
  store.commit('instancesState/setThemeLoaded', true)

  if (Vue.$cookies.get(`${event}.cookie.typekit`) === 'accepted' && config?.config[0]?.defaults.adobeTypekit) {
    const typekitUrl = config.config[0].defaults.adobeTypekit
    const typekit = document.createElement('link')
    typekit.rel = 'stylesheet'
    typekit.href = typekitUrl
    document.head.appendChild(typekit)
  }

  next()
}
