import utils from '~/utilities/utils'
import _isEqual from 'lodash/isEqual'

const tagMixins = {
  computed: {
    activeRoute() {
      return this.$route
    },
  },
  methods: {
    tag(obj, isApp) {
      if (!obj.url || ((obj.dataType === 'link' || obj.dataType === 'qnalink') && isApp)) {
        return 'div'
      }
      if (!utils.isObject(obj.url) && utils.isUrl(obj.url)) {
        return 'a'
      }
      return 'router-link'
    },
    tagProps(obj) {
      if (!obj.url) {
        return {}
      }
      const props = {}
      props.active = this.isActive(obj)
      if (utils.isUrl(obj.url)) {
        props.href = obj.url
        props.target = obj.target ? obj.target : '_self'
        props.dataType = obj.dataType ? obj.dataType : ''
      } else {
        let route = null
        if (utils.isObject(obj.url)) {
          route = utils.appendDefaultParamsToRoute(obj.url)
        } else {
          route = utils.getRouteWithDefaultParams(obj.url)
        }
        const app = document.getElementById('app')
        if (localStorage.getItem('isApp') === 'true' && obj.name === 'PROGRAMME' && app.classList.contains('ers2022')) {
          route.query.viewType = 'list'
        }
        props.to = route
      }
      return props
    },
    isActive(item) {
      const url = item.url
      const activePath = this.activeRoute.path
      const activeRoute = {
        name: this.activeRoute.name,
        params: this.activeRoute.params,
      }
      Object.keys(activeRoute.params).forEach((key) => activeRoute.params[key] === undefined && delete activeRoute.params[key])
      if (Object.keys(this.activeRoute.query).length) {
        activeRoute.query = this.activeRoute.query
      }

      if (typeof url === 'object'
        && url.name === this.activeRoute.name
        && _isEqual(url, activeRoute)) {
        return true
      }
      if (typeof url === 'string' && url.includes(activePath)) {
        return true
      }
      return this.activeRoute.name === item.url
    },
  },
}

export default tagMixins
