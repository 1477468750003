<template>
  <container
    :style="sideNavStyle"
    :breakpoint="breakpoint"
  >
    <nav-close-btn
      class="bannerMenu-menu-toggle"
      @click="$emit('sideNavigationToggle')"
      :breakpoint="breakpoint"
      v-if="sideNavigationOpen"
    >
      <span class="bannerMenu-menu-toggle-icon">
        <font-awesome-icon :icon="['fa', 'arrow-left']" />
      </span>
      <span class="bannerMenu-menu-toggle-text">{{ $t("VMX.mobile_nav_close") }}</span>
      <template v-if="logoInMobileNav">
        <img
          :src="mainLogo"
          style="height: 20px; border-radius: .25rem; left: 50%; transform: translateX(-50%); position: absolute;"
        />
      </template>
    </nav-close-btn>
    <template v-if="tileNavigation && isMobile">
      <div
        class="vmx-header__nav-items-container tile-navigation"
        :style="tileContainerStyle"
      >
        <template v-if="fullWidthBanner && !sideNavigationOpen && width >= breakpoint">
          <component
            :is="tag(banners.main)"
            v-bind="tagProps(banners.main)"
            class="bannerMenu_fullwidth-logo_img-container"
          >
            <template v-if="banners.main">
              <main-logo-alt
                :src="banners.main.image"
                :alt="banners.main.alt"
                :style="banners.main.link ? 'cursor: pointer' : null"
                class="bannerMenu_fullwidth-logo_img"
              />
            </template>
          </component>
        </template>
        <item-div
          class="vmx-header__nav-item-container"
          v-for="(item, index) in items"
          :key="index"
          :breakpoint="breakpoint"
          v-bind="navItemProps(item)"
          :hidden="!checkIsComponentTimeActive(item) ? true : (('showMenuItem' in item) ? !item.showMenuItem : false)"
          :active="navItemProps(item).active ? 'rgba(255, 255, 255, 0.3)' : null"
          :ref="`menu-item-${index}`"
          :only-in-mobile="item.onlyInMobile ? breakpoint : 99999"
          :style="tileStyle"
        >
          <template v-if="item.subMenu">
            <dropdown-menu
              v-if="isApp === true ? !(('onlyInVMX' in item) && item.onlyInVMX) : true && displayHeaderContent(item)"
              :items="item.subMenu"
              :width="width"
              :item-nr="index"
              @subActive="setActive"
            >
              <span
                class="bannerMenu__menu-item-dropdown"
                :class="tileNavigation && isMobile ? 'bannerMenu__menu-item-dropdown-tile' : ''"
              >
                <icon-wrapper 
                  :breakpoint="breakpoint"
                  class="bannerMenu__menu-item-dropdown-icon"
                >
                  <template v-if="item.icon">
                    <font-awesome-icon
                      :icon="icon(item.icon)"
                    />
                  </template>
                </icon-wrapper>
                <span :class="tileNavigation && isMobile ? 'bannerMenu__menu-item-content-tileNavigation' : ''">{{ item.name }}</span>
              </span>
            </dropdown-menu>
          </template>
          <template v-else>
            <component
              v-if="isApp === true ? !(('onlyInVMX' in item) && item.onlyInVMX) : true && displayHeaderContent(item)"
              :is="tag(item, isApp)"
              v-bind="tagProps(item)"
              class="_nav-item"
              :class="tileNavigation && isMobile ? '_nav-item_tile' : ''"
              @click="action(tagProps(item))"
            >
              <icon-wrapper 
                :breakpoint="breakpoint"
                class="bannerMenu__menu-item-icon"
              >
                <template v-if="item.icon">
                  <font-awesome-icon
                    :style="tileIconStyle"
                    :icon="icon(item.icon)"
                  />
                </template>
              </icon-wrapper>
              <span :class="tileNavigation && isMobile ? 'bannerMenu__menu-item-content-tileNavigation' : ''">{{ item.name }}</span>
            </component>
          </template>
        </item-div>
      </div>
    </template>
    <template v-else>
      <template v-if="fullWidthBanner && !sideNavigationOpen && width >= breakpoint">
        <component
          :is="tag(banners.main)"
          v-bind="tagProps(banners.main)"
          class="bannerMenu_fullwidth-logo_img-container"
        >
          <template v-if="banners.main">
            <main-logo-alt
              :src="banners.main.image"
              :alt="banners.main.alt"
              :style="banners.main.link ? 'cursor: pointer' : null"
              class="bannerMenu_fullwidth-logo_img"
            />
          </template>
        </component>
      </template>
      <item-div
        class="vmx-header__nav-item-container"
        v-for="(item, index) in items"
        :key="index"
        :breakpoint="breakpoint"
        v-bind="navItemProps(item)"
        :hidden="!checkIsComponentTimeActive(item) ? true : (('showMenuItem' in item) ? !item.showMenuItem : false)"
        :active="navItemProps(item).active ? 'rgba(255, 255, 255, 0.3)' : null"
        :ref="`menu-item-${index}`"
        :only-in-mobile="item.onlyInMobile ? breakpoint : 99999"
        :style="tileStyle"
        @click="action(tagProps(item))"
      >
        <template v-if="item.subMenu">
          <dropdown-menu
            v-if="isApp === true ? !(('onlyInVMX' in item) && item.onlyInVMX) : true && displayHeaderContent(item)"
            :items="item.subMenu"
            :width="width"
            :item-nr="index"
            @subActive="setActive"
          >
            <span
              class="bannerMenu__menu-item-dropdown"
              :class="tileNavigation && isMobile ? '_nav-item_tile' : ''"
            >
              <icon-wrapper 
                :breakpoint="breakpoint"
                class="bannerMenu__menu-item-dropdown-icon"
                :style="tileNavigation && isMobile ? 'width: 2rem' : ''"
              >
                <template v-if="item.icon">
                  <font-awesome-icon
                    :icon="icon(item.icon)"
                  />
                </template>
              </icon-wrapper>
              <span :class="tileNavigation && isMobile ? 'bannerMenu__menu-item-content-tileNavigation' : ''">{{ item.name }}</span>
            </span>
          </dropdown-menu>
        </template>
        <template v-else>
          <component
            v-if="isApp === true ? !(('onlyInVMX' in item) && item.onlyInVMX) : true && displayHeaderContent(item)"
            :is="tag(item, isApp)"
            v-bind="tagProps(item)"
            class="_nav-item"
            :class="tileNavigation && isMobile ? '_nav-item_tile' : ''"
            @click="action(tagProps(item))"
          >
            <icon-wrapper 
              :breakpoint="breakpoint"
              class="bannerMenu__menu-item-icon"
              :style="tileNavigation && isMobile ? 'width: 2rem' : ''"
            >
              <template v-if="item.icon">
                <font-awesome-icon
                  :style="tileIconStyle"
                  :icon="icon(item.icon)"
                />
              </template>
            </icon-wrapper>
            <span :class="tileNavigation && isMobile ? 'bannerMenu__menu-item-content-tileNavigation' : ''">{{ item.name }}</span>
          </component>
        </template>
      </item-div>
      <template v-if="fullWidthBanner && !isMobile">
        <template v-if="loginStatus">
          <a
            href
            @click.prevent="logout"
            class="vmx-header__logout-button _nav-item"
          >
            {{ $t("VMX.user_logout") }}
          </a>
        </template>
        <template v-else>
          <a
            href
            @click.prevent="login"
            class="vmx-header__login-button _nav-item"
          >
            {{ $t("VMX.user_login") }}
          </a>
        </template>
      </template>
    </template>
    <template v-if="width <= this.breakpoint">
      <separator class="bannerMenu_mobile-separator" />
      <slot />
    </template>
  </container>
</template>

<script>

import Vue from 'vue'
import VueCookies from 'vue-cookies'
import utils from '~/utilities/utils'
import config from '~/config'
import { mapGetters } from 'vuex'
import userState from '~/states/user'
import styled from '@u-rogel/vue-emotion'
import componentIsActiveMixins from '~/mixins/componentIsActiveMixins'
import tagMixins from '~/mixins/tagMixins'
import headerMixins from '~/mixins/headerMixins'
import DropdownMenu from './DropdownMenu.vue'


Vue.use(VueCookies)

const Container = styled.div`
  height: 40px;
  @media (min-width: ${({ breakpoint }) => breakpoint + 1 || 1025}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1400px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: auto;
  }


  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 998;
   top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background-color: var(--header-navbar-bg);
  }
  @media (min-width: ${({ breakpoint }) => breakpoint + 1 || 1025}px) {
    position: relative
  }
`({
  breakpoint: Number,
  mobileContainerWidth: Number,
  mobileContainerPadding: String,
})

const ItemDiv = styled.div`
  .router-link-exact-active.active {
    background-color: rgba(255, 255, 255, 0.3)
  }
  &:hover a {
   text-decoration: none;
  }
  background-color: ${({ active }) => active};
  //&.sub-active {
  //  background-color: rgba(255, 255, 255, 0.2)
  //}
  @media (min-width: ${({ breakpoint }) => breakpoint + 1 || 1025}px) {
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    > div {
      position: relative;
    }
  }

  > :nth-child(1) {
    color: var(--header-navbar-bg-contrast);
    padding: 0 25px;
    white-space: nowrap;
    @media (max-width: 1366px) {
      padding: 10px 15px;
      display: block;
    }
    @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
      padding: 10px 0;
      display: block;
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    padding: 0 18px 0 20px;
    text-decoration: none;
    transition: 0.3s;
  }
  @media (min-width: ${({ onlyInMobile }) => onlyInMobile + 1}px) {
    display: none;
  }
`({
  breakpoint: Number,
  active: String,
  onlyInMobile: Number,
})

const IconWrapper = styled.span`
  @media (min-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    display: none;
  }
  display: inline-block;
  width: 21px;
  color: var(--header-navbar-bg-contrast);
  svg {
    margin-right: 5px;
  }

`({
  breakpoint: Number,
})

const MainLogoAlt = styled.img`
  max-width: 100%;
  max-height: 100%;
`()

const NavCloseBtn = styled.div`
  display: none;
  position: relative;
  > :nth-child(1) {
    padding: 10px 10px 10px 15px;
  }
  span {
    color: var(--header-navbar-bg-contrast);
    font-weight: bold;
    cursor: pointer;
  }
  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    padding: 0 0 8px 0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
`({
  breakpoint: Number,
})

const Separator = styled.div`
  border-bottom: 1px solid var(--header-navbar-bg-contrast);
  opacity: .5;
  margin: 15px;
`()

export default {
  name: 'BannerMenu',
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  mixins: [
    tagMixins,
    headerMixins,
    componentIsActiveMixins.componentProps,
    componentIsActiveMixins.method,
  ],
  components: {
    Container,
    ItemDiv,
    NavCloseBtn,
    MainLogoAlt,
    DropdownMenu,
    IconWrapper,
    Separator,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    banners: {
      type: Object,
      default() {
        return {}
      },
    },
    width: {
      type: Number,
      default: 0,
    },
    sideNavigationOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      instance: 'instancesState/getInstance',
      event: 'instancesState/getEvent',
      language: 'instancesState/getLanguage',
      configForKey: 'config/configForKey',
      user: 'user/user',
    }),
    loginStatus() {
      return !!userState.getUser(this.instance)
    },
    backendConfig() {
      return this.configForKey('app_config').config[0]
    },
    externalAuth() {
      if (this.backendConfig.defaults?.externalAuth) {
        return this.backendConfig.defaults.externalAuth.externalLogin
      }
      return false
    },
    isApp() {
      return localStorage.getItem('isApp') === 'true'
    },
    isMobile() {
      if (this.width < this.breakpoint) {
        return true
      }
      return false
    },
    sideNavStyle() {
      if (this.width > this.breakpoint) {
        return null
      }
      return this.sideNavigationOpen ? `width: ${this.mobileContainerWidth}%; padding: ${this.mobileContainerPadding}` : 'width: 0; padding: 0'
    },
    tileNavigation() {
      if (this.headerConfig.tileNavigation) {
        return this.headerConfig.tileNavigation
      }
      return false
    },
    tileContainerStyle() {
      if (!this.tileNavigation) {
        return null
      }
      return this.tileNavigation && this.isMobile ? 'display: flex; gap: 0.5rem; flex-wrap: wrap' : ''
    },
    tileStyle() {
      if (!this.tileNavigation) {
        return null
      }
      return this.tileNavigation && this.isMobile ? 'flex: 0 1 calc(50% - 0.25rem); max-width: calc(50% - 0.25rem); padding: 0.25rem; border: 1px solid #fff; border-radius: 0.25rem;' : ''
    },
    tileIconStyle() {
      if (!this.tileNavigation) {
        return null
      }
      return this.tileNavigation && this.isMobile ? 'width: .5rem' : ''
    },
  },
  methods: {
    action(url) {
      const pages = ['PosterPage', 'IndustrySymposiaPage', 'PresentationSearchPage', 'FacultySearchPage', 'PagSearchPage', 'AbstractSearchPage']
      if (pages.includes(this.$route.name)) {
        this.$root.$emit('rerender')
      }
      if ((url.dataType === 'link' || url.dataType === 'qnalink') && this.isApp) {
        if (url.dataType === 'qnalink') {
          window.openLink(`${url.href}?access_token=${Vue.$cookies.get('access_token')}&user_id=${Vue.$cookies.get('user_id')}&event_id=${Vue.$cookies.get('event_id')}&isApp=${this.isApp}`)
          return
        }
        window.openLink(url.href)
        return
      }

      if (url.dataType === 'qnalink') {
        window.open(`${url.href}?access_token=${Vue.$cookies.get('access_token')}&user_id=${Vue.$cookies.get('user_id')}&event_id=${Vue.$cookies.get('event_id')}&isApp=${this.isApp}`, '_blank')
      }

    },
    displayHeaderContent(params) {
      if ('display' in params) {
        return params.display[localStorage.getItem('content')]
      }
      return true
    },
    tag(obj) {
      if (!obj?.url) {
        return 'div'
      }
      if (!utils.isObject(obj.url) && utils.isUrl(obj.url)) {
        return 'a'
      }
      return 'router-link'
    },
    tagProps(obj) {
      if (!obj?.url) {
        return {}
      }
      const props = {}
      if (utils.isUrl(obj.url)) {
        props.href = obj.url
        props.target = obj.target ? obj.target : '_self'
      } else {
        let route = null
        if (utils.isObject(obj.url)) {
          route = utils.appendDefaultParamsToRoute(obj.url)
        } else {
          route = utils.getRouteWithDefaultParams(obj.url)
        }
        props.to = route
      }
      return props
    },
    tracking(name) {
      if (utils.advancedTracking()) {
        this.TrackingUtil.trackEvent(
          'HeaderMenu',
          'Click',
          utils.advancedTrackingFormat(
            this.user.UserId,
            'Event',
            'MenuItem',
            name,
          ),
        )
      } else {
        this.TrackingUtil.trackEvent('HeaderMenu', 'Click', name)
      }
    },
    login() {
      this.tracking('login')
      userState.unsetUser(this.instance)
      this.$store.commit('instancesState/setToken', null)

      if (this.backendConfig.defaults?.forceLoginPage || (localStorage.getItem('isApp') === 'true' && this.backendConfig.defaults?.forceLoginPageApp)) {
        this.$router.push(utils.getRouteWithDefaultParams('LoginPage'))
        return
      }
      const instanceConfig = config.getInstanceConfig(this.instance)
      let returnPath = this.$route.path
      if (
        this.$route.name === 'AuthPage'
        || this.$route.name === 'NoaccessPage'
      ) {
        returnPath = `/Home/${this.instance}/${this.event}/${this.language}`
      }

      const redirectUri = new URL(window.location.origin + returnPath)
      if (this.backendConfig.SSOLogin && Object.prototype.hasOwnProperty.call(instanceConfig, 'sso')) {
        const alternativePath = this.backendConfig?.SSOReturnUrl ? this.backendConfig.SSOReturnUrl : null
        const { identityUrl } = instanceConfig
        const loginUrl = instanceConfig.sso.url
        const prevQuery = utils.queryString(this.$route.query)
        const defaultParams = {
          shortname: this.event,
          language: this.language,
          returnUrl: window.location.origin + (alternativePath || this.$route.path)+'?'+prevQuery,
        }
        const defaultQueryStrings = utils.queryString(defaultParams)

        if (Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'context')) {
          Object.assign(defaultParams, { context: instanceConfig.sso.context })
        }
        if (Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'provider')) {
          Object.assign(defaultParams, { provider: instanceConfig.sso.provider })
        }

        let SSOUrl = `${identityUrl + loginUrl}?${defaultQueryStrings}`
        
        
        if(this.externalAuth) {
          const externalLoginUrl = this.backendConfig.defaults.externalAuth.loginUrl
          const externalLoginParams = {
            returnUrl: window.location.origin + (alternativePath || this.$route.path) +'?'+prevQuery,
          }
          const externalLoginQueryStrings = utils.queryString(externalLoginParams)
          SSOUrl = `${externalLoginUrl}${externalLoginUrl.includes('returnUrl') ? '' : '&'+externalLoginQueryStrings}`
        }
        
        localStorage.setItem('externalLogin', true)

        window.location.href = SSOUrl
        return
      }

      const params = {
        returnUrl: redirectUri,
      }
      const queryString = utils.queryString(params)
      const url = `${instanceConfig.webformsUrl}/Home/GetToken?${queryString}`
      localStorage.setItem('tryLogin', '123')
      if (!config.getInstanceConfig(this.instance).identityUrl) {
        utils.redirectOAuthAuthorize(this.$route)
        return
      }

      if (this.externalAuth) {
        if (this.backendConfig.defaults.externalAuth.loginUrl) {
          // window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
          window.location.href = this.backendConfig.defaults.externalAuth.loginUrl
          return
        }
      }
      if (this.$route.name !== 'LoginPage') {
        window.location.href = url
      }
    },
    logout() {
      this.tracking('logout')
      this.$cookies.remove('accesOnDays')
      this.$cookies.remove('tickets')
      this.$cookies.remove('hcp')
      userState.unsetUser(this.instance)
      this.$store.commit('instancesState/setToken', null)
      localStorage.removeItem('externalLogin')
      localStorage.removeItem('recheckedUserTicket')
      const webFormsUrl = config.getInstanceConfig(this.instance).webformsUrl
      const params = {
        eventName: this.event,
      }
      const queryStrings = utils.queryString(params)
      let logoutUrl = '/Main/Logout'

      if (this.externalAuth) {
        if (this.backendConfig.defaults.externalAuth.logoutUrl) {
          logoutUrl = this.backendConfig.defaults.externalAuth.logoutUrl
          // window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
          window.location.href = logoutUrl
          return
        }
      }
      // this.$router.push(utils.getRouteWithDefaultParams('LoginPage'))
      window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
    },
    icon(icon) {
      // eslint-disable-next-line prefer-const
      let [first, ...rest] = icon.split('-')
      rest = rest.join('-')
      if (first && rest) {
        return [first, rest]
      }
      return ['fa', 'question']
    },
    navItemProps(item) {
      return {
        active: this.isActive(item),
      }
    },
    setActive(nr) {
      const element = this.$refs[`menu-item-${nr}`]
      if (element) {
        // element[0].classList.add('sub-active')
      }
    },
  },
  watch: {
    $route() {
      if (this.sideNavigationOpen) this.$emit('sideNavigationToggle')
    },
  },
}
</script>
