<template>
  <!-- <div class="pag-evaluation" id="root6" v-if="checkSessionStartTime() && VMUserId && VMEventId"/> -->
  <div class="pag-evaluation" id="root6"/>
</template>
<script>

import VueCookies from 'vue-cookies'
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone' // load on demand

const isBetween = require('dayjs/plugin/isBetween')

dayjs.extend(isBetween)
dayjs.extend(timezone)
Vue.use(VueCookies)

export default {
  name: 'EvaluationComponent',
  inheritAttrs: false,
  data() {
    return {
      manageSessionId: this.session.SessionId || this.session.Id,
      baseApi: this.$store.getters['instancesState/getMTVApiBaseUrl'],
      VMUserId: Vue.$cookies.get('user_id') || null,
      VMEventId: Vue.$cookies.get('event_id') || null,
      VMAccessToken: Vue.$cookies.get('access_token') || null,
    }
  },
  props: {
    session: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      config: 'config/configForKey',
      user: 'user/user',
    }),
    activateEvaluationUrlsWithoutUser() {
      const c = this.config('app_config').configV2
      return c?.defaultV2?.activateEvaluationUrlsWithoutUser
        ? c.defaultV2.activateEvaluationUrlsWithoutUser
        : false
    },
  },
  mounted() {
    window.loadEvaluationVMX(this.baseApi, this.VMEventId, this.manageSessionId, this.VMUserId, this.VMAccessToken)
  },
  methods: {
    checkSessionStartTime() {
      const timeZoneConfig = this.$store.getters['config/configForKey']('app_config')
      const eventTimezone = timeZoneConfig.config[0].EventTimeZone ? timeZoneConfig.config[0].EventTimeZone : 'Europe/Berlin'
      const sessionStart = dayjs(dayjs(this.session.StartDateTime).tz(eventTimezone, true))

      dayjs.tz.setDefault(eventTimezone)

      if (this.$store.getters['instancesState/getSuperUser']) return true
      return dayjs(dayjs().tz(eventTimezone)).isAfter(sessionStart)
    },
  }


}
</script>
