import Fuse from 'fuse.js'
import programApi from '../../api/program'

function sortByFirstname(a, b) {
  return (a.Firstname.toLowerCase() || '').localeCompare(b.Firstname.toLowerCase() || '')
}

function sortBySurname(a, b) {
  return (a.Surname.toLowerCase() || '').localeCompare(b.Surname.toLowerCase() || '')
}

function sortByCountry(a, b) {
  return (a.Country.toLowerCase() || '').localeCompare(b.Country.toLowerCase() || '')
}

const pagAuthors = {
  namespaced: true,
  state: {
    cityTags: [],
    countryTags: [],
    groupTags: [],
    authors: [],
    fetchAuthorPromises: [],
    loading: false,
    fuseJs: null,
  },
  mutations: {
    setAuthors(state, payload) {
      state.authors = payload
    },
    setFetchAuthorsPromise(state, payload) {
      state.fetchAuthorPromises = payload
    },
    setCountryTags(state, payload) {
      state.countryTags = payload
    },
    setGroupTags(state, payload) {
      state.groupTags = payload
    },
    setCityTags(state, payload) {
      state.cityTags = payload
    },
  },
  actions: {
    async loadAuthors({ commit, state }) {
      if (state.authors.length > 0) {
        return state.authors
      }
      if (state.fetchAuthorPromises.length > 0) {
        return state.fetchAuthorPromises
      }
      const fetchPromise = await programApi.getAuthors().then(async (list) => {
        commit('setAuthors', list)
      })

      commit('setFetchAuthorsPromise', fetchPromise)

      let countryTags = []
      let cityTags = []
      let groupTags = []
      if (state.authors.length > 0) {
        cityTags = [...new Set(state.authors.map((speaker) => speaker.City))]
        countryTags = [...new Set(state.authors.map((speaker) => speaker.Country))]
        groupTags = [...new Set(state.authors.reduce((newArr, speaker) => [...newArr, ...speaker.GroupsArray || []], []))]
      }
      commit('setCityTags', cityTags)
      commit('setCountryTags', countryTags)
      commit('setGroupTags', groupTags)
      return fetchPromise
    },

    async search({
      commit,
      state,
      dispatch,
    }, payload) {
      if (!state.authors.length) {
        await dispatch('loadAuthors')
      }
      let result = state.authors

      let cityResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'cityTags') && payload?.cityTags.length > 0) {
        cityResult = result.filter((item) => payload.cityTags.includes(item.City))
      }

      let countryResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'countryTags') && payload?.countryTags.length > 0) {
        countryResult = result.filter((item) => payload.countryTags.includes(item.Country))
      }

      let groupTagResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'groupTags') && payload?.groupTags.length > 0) {
        groupTagResult = result.filter((speaker) => payload.groupTags.some((group) => speaker.GroupsArray.includes(group)))
      }

      if (countryResult.length > 0 || cityResult.length > 0 || groupTagResult.length > 0) {
        result = [...new Set(countryResult.concat(...cityResult).concat(...groupTagResult))]
      }

      if (payload && payload.text) {
        const options = {
          keys: [
            'Biography',
            'City',
            'Country',
            'Email',
            'Profession',
            'Firstname',
            'Surname',
            'TitleFirstnameSurname',
          ],
          maxPatternLength: 32,
          minMatchCharLength: 3,
          threshold: 0.0,
          ignoreLocation: true,
        }

        const fuse = new Fuse(result, options)
        const fuseResult = fuse.search(payload.text)
        const tempResult = []
        for (let i = 0, len = fuseResult.length; i < len; i += 1) { // TODO: check if for is really needed
          tempResult.push(fuseResult[i].item)
        }
        result = tempResult
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'sortBy') && payload.sortBy) {
        switch (payload.sortBy) {
          case 'firstname':
            result.sort(sortByFirstname)
            break
          case 'surname':
            result.sort(sortBySurname)
            break
          case 'country':
            result.sort(sortByCountry)
            break
          default:
            break
        }
      }
      return result
    },

  },
  getters: {
    authors(state) {
      return state.authors
    },
    countryTags(state) {
      return state.countryTags
    },
    cityTags(state) {
      return state.cityTags
    },
    groupTags(state) {
      return state.groupTags
    },
  },
}

export default pagAuthors
