<template>
  <container class="bannerMenu_my-area_user-section">
    <user-img class="bannerMenu_my-area_user-section-img">
      <img
        :src="userPicture"
        alt="mobile-user-picture"
      />
    </user-img>
    <username class="bannerMenu_my-area_user-section-user">
      <span>{{ `${userFirstname} ${userSurname}` }}</span>
      <router-link :to="{ name: 'UserFormPage' }">
        {{ $t("VMX.mobile_nav_edit_profile") }}
      </router-link>
    </username>
  </container>
</template>

<script>
import styled from '@u-rogel/vue-emotion'
import { mapGetters } from 'vuex'

const Container = styled.div`
  padding: 10px 15px;
  color: var(--header-navbar-bg-contrast);
  @media (min-width: 280px) {
    display: flex;
    align-items: center;
    gap: 5px;
}
`()

const UserImg = styled.div`
  img {
    border: solid 2px var(--header-navbar-bg-contrast);
    border-radius: 24px;
    height: 50px;
    width: 50px;
    max-width: 50px;
    max-height: 50px;
    overflow: hidden;
    object-fit: contain;
  }
`()

const Username = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
  span {
    font-weight: bold;
    font-size: 15px;
  }
  a, a:hover {
    padding: 0;
    font-size: 12px;
    overflow: hidden;
  }
`()

export default {
  name: 'UserInfo',
  components: {
    Container,
    UserImg,
    Username,
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    userPicture() {
      if (this.user?.ProfilePicturePath) {
        return this.user.ProfilePicturePath
      }
      return '/assets/img/user-no-pic.jpeg'
    },
    userFirstname() {
      if (this.user?.Firstname) {
        return this.user.Firstname
      }
      return ''
    },
    userSurname() {
      if (this.user?.Surname) {
        return this.user.Surname
      }
      return ''
    },
  },
}
</script>
