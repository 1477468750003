import dayjs from 'dayjs'

export default {
  computed: {
    language() {
      return this.$store.getters['instancesState/getLanguage']
    },
    dateAppConfig() {
      return this.$store.getters['config/configForKey']('app_config')
    },
  },
  methods: {
    formatDayTitle(dayDate, dayFormat) {
      const date = dayjs(dayDate)
      const programConfig = this.dateAppConfig.config[0].programme[0]
      if (programConfig.dateformat && this.language !== 'de-DE' && dayFormat === undefined) {
        return date.format(programConfig.dateformat)
      }
      if (this.language === 'de-DE' && dayFormat === undefined) {
        return date.format('dddd, DD. ') + date.format('MMMM YYYY')
      }
      return date.format(dayFormat)
    },
    formatDate(value) {
      const programConfig = this.dateAppConfig.config[0].programme[0]
      if (programConfig.dateformat && value) {
        return dayjs(String(value)).format(programConfig.dateformat)
      }
      if (value && !programConfig.dateformat) {
        return dayjs(String(value)).format('DD MMMM YYYY')
      }
      return null
    },
    formatDateD(value) {
      if (value) {
        return dayjs(String(value)).format('DD. MMMM YYYY')
      }
      return null
    },
    formatMTVDate(value) {
      if (value) {
        return dayjs(String(value)).format('MMMM DD, YYYY HH:mm')
      }
      return null
    },
    formatDateTime(value) {
      if (value) {
        return dayjs(String(value)).format('HH:mm')
      }
      return null
    },
  },
}
