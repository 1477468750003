import Fuse from 'fuse.js'
import store from '~/store'
import programApi from '../../api/program'

function sortByName(a, b) {
  return (a.Text.toLowerCase() || a.TextWithFormat).localeCompare(b.Text.toLowerCase() || b.TextWithFormat)
}

function sortByDate(a, b) {
  return (a.StartDateTime || '').localeCompare(b.StartDateTime || '')
}

function sortByRoom(a, b) {
  return (a.RoomName || '').localeCompare(b.RoomName || '')
}

function sortByPresentationId(a, b) {
  return (a.Number || '').localeCompare(b.Number || '')
}

function retrievePresentations(sessionList) {
  const reducedResult = sessionList.reduce((newArr, session) => {
    const newpresentations = session.Presentations.map((presentation) => ({
      IsPoster: session.IsPoster,
      SessionStart: session.StartDateTime,
      SessionEnd: session.EndDateTime,
      SessionGroups: session.SessionGroups,
      SessionTypeName: session.SessionTypeName,
      SessionTags: session.SessionTags,
      SessionTypeGrade: session.SessionTypeGrade,
      IsLiveSession: session.IsLiveSession,
      IsOnDemandSession: session.IsOnDemandSession,
      SessionId: session.SessionId,
      EnableViewCounter: session.EnableViewCounter,
      RoomName: session.RoomName,
      ...presentation,
    }))
    return [...newArr, ...newpresentations || {}]
  }, [])
  return reducedResult
}
const pagPresentations = {
  namespaced: true,
  state: {
    dayTags: [],
    typeTags: [],
    groupTags: [],
    keywordTags: [],
    posterDayTags: [],
    posterTypeTags: [],
    posterGroupTags: [],
    presentations: [],
    fetchPresPromises: [],
    loading: false,
    fuseJs: null,
  },
  mutations: {
    setPresentations(state, payload) {
      state.presentations = payload
    },
    setFetchPresPromise(state, payload) {
      state.fetchPresPromises = payload
    },
    setDayTags(state, payload) {
      state.dayTags = payload
    },
    setGroupTags(state, payload) {
      state.groupTags = payload
    },
    setKeywordTags(state, payload) {
      state.keywordTags = payload
    },
    setTypeTags(state, payload) {
      state.typeTags = payload
    },
    setPosterDayTags(state, payload) {
      state.posterDayTags = payload
    },
    setPosterGroupTags(state, payload) {
      state.PosterGroupTags = payload
    },
    setPosterTypeTags(state, payload) {
      state.posterTypeTags = payload
    },

  },
  actions: {
    async loadPresentations({ commit, state }) {
      if (state.presentations?.length === 0) {
        commit('setPresentations', retrievePresentations(store.getters['pagSessions/sessions']))
      }
      // if (state.presentations.length > 0) {
      //   return state.presentations
      // }
      // if (state.fetchPresPromises.length > 0) {
      //   return state.fetchPresPromises
      // }
      // const fetchPromise = await programApi.getSessions().then(async (list) => {
      //   commit('setPresentations', retrievePresentations(list))
      // })

      // commit('setFetchPresPromise', fetchPromise)
      // return fetchPromise
    },

    async search({
      commit,
      state,
      dispatch,
    }, payload) {
      if (!state.presentations.length) {
        await dispatch('loadPresentations')
      }
      let result = state.presentations
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'segment') && payload.segment) {
        switch (payload.segment) {
          case 'Poster':
            result = result.filter((presentation) => presentation.IsPoster)
            break
          case 'Programme':
            result = result.filter((presentation) => !presentation.IsPoster)
            break
          default:
            break
        }
      }

      if (
        payload
        && Object.prototype.hasOwnProperty.call(payload, 'preFilter')
        && payload.preFilter
        && Object.prototype.hasOwnProperty.call(payload, 'filterActive')
        && payload.filterActive
      ) {
        const preFilter = payload.preFilter.find((filter) => filter.name === payload.filterActive)
        if (preFilter.sessionGrade) {
          switch (preFilter.sessionGrade) {
            case 'Industry':
              result = result.filter((presentation) => presentation.SessionTypeGrade === 'Industry')
              break
            case 'Scientific':
              result = result.filter((presentation) => presentation.SessionTypeGrade === 'Scientific')
              break
            default:
              break
          }
        }
        if (preFilter.presentationId?.length > 0) {
          result = result.filter((presentation) => preFilter.presentationId.includes(presentation.PresentationId))
        }
        if (preFilter.date?.length > 0) {
          result = result.filter((presentation) => preFilter.date.includes(presentation.StartDateTime.substring(0, 10)))
        }
        if (preFilter.sessionGroups?.length > 0) {
          result = result.filter((presentation) => preFilter.sessionGroups.some((preItem) => presentation.SessionGroups.map((item) => item.Name).includes(preItem)))
        }
        if (preFilter.sessionTypes?.length > 0) {
          result = result.filter((presentation) => preFilter.sessionTypes.includes(presentation.SessionTypeName))
        }
        if (preFilter.sessionTags?.length > 0) {
          result = result.filter((presentation) => preFilter.sessionTags.includes(presentation.SessionTags))
        }
        if (preFilter.rooms?.length > 0) {
          result = result.filter((presentation) => preFilter.rooms.includes(presentation.RoomName))
        }

        let ondemandResult = []
        if (preFilter.isOnDemandSession) {
          ondemandResult = result.filter((presentation) => presentation.IsOnDemandSession)
        }

        let liveResult = []
        if (preFilter.isLiveSession) {
          liveResult = result.filter((presentation) => presentation.IsLiveSession)
        }

        if (ondemandResult.length > 0 || liveResult.length > 0) {
          result = [...new Set(ondemandResult.concat(...liveResult))]
        }

        if (preFilter.OnlyLiveSessions) {
          result = result.filter((presentation) => presentation.IsLiveSession && !presentation.IsOnDemandSession)
        }

        if (preFilter.OnlyOndemandSessions) {
          result = result.filter((presentation) => (!presentation.IsLiveSession && presentation.IsOnDemandSession))
        }
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'dayTags') && payload?.dayTags.length > 0) {
        const dayTagArr = payload.dayTags.map((item) => item.value)
        result = result.filter((presentation) => dayTagArr.includes((presentation.StartDateTime || '').substring(0, 10)))
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'groupTags') && payload?.groupTags.length > 0) {
        result = result.filter((presentation) => payload.groupTags.some((group) => presentation.SessionGroups.map((item) => item.Name).includes(group)))
      }

      let typeResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'typeTags') && payload?.typeTags.length > 0) {
        typeResult = result.filter((item) => payload.typeTags.includes(item.SessionTypeName))
      }

      let roomResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'roomTags') && payload?.roomTags.length > 0) {
        roomResult = result.filter((item) => payload.roomTags.includes(item.RoomName))
      }

      let keywordResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'keywordTags') && payload?.keywordTags.length > 0) {
        keywordResult = result.filter((presentation) => payload.keywordTags.some((keyword) => presentation.Keywords.includes(keyword)))
      }

      if (roomResult.length > 0 || typeResult.length > 0 || keywordResult.length > 0) {
        result = [...new Set(typeResult.concat(...roomResult).concat(...keywordResult))]
      }

      if (payload && payload.text) {
        const options = {
          keys: [
            'Number',
            'PresentationId',
            'RoomName',
            'Summary',
            'Text',
            'TextWithFormat',
            'Speakers.TitleFirstnameSurname',
          ],
          maxPatternLength: 32,
          minMatchCharLength: 3,
          threshold: 0.0,
          ignoreLocation: true,
        }

        const fuse = new Fuse(result, options)
        const fuseResult = fuse.search(payload.text)
        const tempResult = []
        for (let i = 0, len = fuseResult.length; i < len; i += 1) { // TODO: check if for is really needed
          tempResult.push(fuseResult[i].item)
        }
        result = tempResult
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'sortBy') && payload.sortBy) {
        switch (payload.sortBy) {
          case 'alphabetical':
            result.sort(sortByName)
            break
          case 'date':
            result.sort(sortByDate)
            break
          case 'room':
            result.sort(sortByRoom)
            break
          case 'sessionnumber':
            result.sort(sortByPresentationId)
            break
          default:
            break
        }
      }

      return result
    },

  },
  getters: {
    presentations(state) {
      return state.presentations
    },
  },
}

export default pagPresentations
