import apiBase from './base'
import store from '../store'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getHighlightedSessions(payload) {
  const eventShort = payload.eventShort || store.getters['instancesState/getEvent']
  const locale = store.getters['instancesState/getLanguage']

  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Public/HighlightedSessions`
 

  const queryParams = {
    shortname: eventShort,
    language: locale,
    programGrade: payload.programGrade,
  }

  const response = await apiBase.get(url, {
    params: queryParams,
  })
  return response.data
}

async function getSelectedSessions(pSessionIds) {
  const eventShort = store.getters['instancesState/getEvent']
  const locale = store.getters['instancesState/getLanguage']

  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Public/SelectedSessions?shortname=${eventShort}&language=${locale}`

  const response = await apiBase.post(url, pSessionIds)
  return response.data
}

async function getHighlightedPresentations() {
  const eventShort = payload.eventShort || store.getters['instancesState/getEvent']
  const locale = store.getters['instancesState/getLanguage']

  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Public/HighlightedPresentation`
  const queryParams = {
    shortname: eventShort,
    language: locale,
  }

  const response = await apiBase.get(url, {
    params: queryParams,
  })
  return response.data
}

async function getHighlightedAuthors() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PAGAuthorsHighlighted?eventid=${eventId}`

  const response = await apiBase.get(url)

  return response.data
}

export default {
  getHighlightedSessions,
  getHighlightedPresentations,
  getHighlightedAuthors,
  getSelectedSessions,
}
