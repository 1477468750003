import dayjs from 'dayjs'
import store from '~/store'
import utils from '../utilities/utils'
import userState from '../states/user'
import userApi from '../api/user'

export default async function ({ next, router, to }) {
  const preview = store.getters['instancesState/getPreview']

  if (preview && (to.name === 'IndustryLandingPageProfile' || to.name === 'ProfilePage')) {
    next()
    return
  }
  const $route = to
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  const instance = store.getters['instancesState/getInstance']

  const eventsWithTicket = [
    'asia2020',
    'esmo2020',
    'esrs2020',
    'esvs2020',
    'UKE012021',
    'aad2021',
    'eadvsymposium2021',
    'par21',
    'escrs2021',
    'eanm21',
    'eha2021',
    'easd2021',
    'esmo2021',
    'asia2021',
  ]

  async function addTicketToUser() {
    // if events have ticket check
    const backendConfig = store.getters['config/configForKey']('app_config').config[0]
    const configEventWithTicket = !backendConfig.defaults?.hasOwnProperty('EventWithTicket')
      ? true
      : backendConfig.defaults.EventWithTicket

    if (eventsWithTicket.includes(event) || configEventWithTicket) {
      const user = await userState.getUser(instance)
      const userTicket = await userApi.getUserTickets()
      let hasTicket = false
      if (userTicket && userTicket.length > 0) {
        hasTicket = true
      }
      userState.setUser(instance, {
        accessToken: user.accessToken,
        validUntil: user.validUntil,
        hasTicket,
      })
      utils.addTicketCookie(userTicket)
    }
  }
  const holdingConfig = store.getters['config/configForKey']('industry_config')

  if (holdingConfig?.config?.Holding?.isActive) {
    // Get JWT token from query
    if (Object.prototype.hasOwnProperty.call($route.query, 'jwt_token')) {
      store.commit('instancesState/setToken', $route.query.jwt_token)
      userState.setUser(instance, {
        accessToken: $route.query.jwt_token,
        validUntil: new Date().getTime() + (43200 * 1000), // 3600 = 1h,
      })
      localStorage.removeItem('hasSeenSplashscreen')
      await addTicketToUser(event, instance, eventsWithTicket)
    }

    // get oauth token from query
    if (Object.prototype.hasOwnProperty.call($route.query, 'state')) {
      const validUntil = new Date().getTime() + (utils.getUrlParameter($route.hash, 'expires_in') * 1000)
      store.commit('instancesState/setToken', utils.getUrlParameter($route.hash, 'access_token'))
      userState.setUser(instance, {
        accessToken: utils.getUrlParameter($route.hash, 'access_token'),
        validUntil,
      })

      await addTicketToUser(event, instance, eventsWithTicket)

      const reloadUrl = new URL(window.location.origin + to.path)
      for (const name in to.query) { // TODO: check if for is really needed
        if (Object.prototype.hasOwnProperty.call(to.query, name)) {
          if (name !== 'state') {
            reloadUrl.searchParams.append(name, to.query[name])
          }
        }
      }

      window.location.href = reloadUrl.href
      return
    }
    const timeZoneConfig = store.getters['config/configForKey']('app_config')

    let hasEventStarted = true
    let hasEventEnded = false
    let disableHoldingForApp = false
    let disableHoldingForTerminal = false
    let timezone = 'Europe/Berlin'
    const eventTz = timeZoneConfig?.config[0].EventTimeZone
    if (eventTz) timezone = eventTz
    await store.dispatch('serverTime/loadServerTime')
    dayjs.tz.setDefault(timezone)
    let serverTime = store.getters['serverTime/getTime']
    if (serverTime) {
      serverTime = dayjs.tz(dayjs(serverTime), timezone)
    } else {
      serverTime = dayjs().tz()
    }

    if (holdingConfig?.config?.Holding?.eventStartTime) {
      hasEventStarted = serverTime.isAfter(dayjs.tz(holdingConfig.config.Holding.eventStartTime, timezone))
    }

    if (holdingConfig?.config?.Holding?.eventEndTime) {
      hasEventEnded = serverTime.isAfter(dayjs.tz(holdingConfig.config.Holding.eventEndTime, timezone))
    }

    if (holdingConfig?.config?.Holding?.disableHoldingForApp) {
      disableHoldingForApp = holdingConfig.config.Holding.disableHoldingForApp
    }

    if (holdingConfig?.config?.Holding?.disableHoldingForTerminal) {
      disableHoldingForTerminal = holdingConfig.config.Holding.disableHoldingForTerminal
    }

    const user = userState.getUser(instance)

    if (user) {
      await store.dispatch('userTicket/loadUserTicket')
      const tickets = store.getters['userTicket/userTicket']
      const backendConfig = store.getters['config/configForKey']('app_config')
      const { previewTicket } = backendConfig.config[0]

      if (tickets) {
        tickets.forEach((ticket) => {
          if (ticket.TicketTypeName?.toLowerCase() === previewTicket?.toLowerCase()) {
            store.commit('instancesState/setSuperUserToggle', true)
            if (localStorage.getItem('sUser') === 'true' || backendConfig.defaultPreviewTicketUnlock) {
              store.commit('instancesState/setSuperUser', true)
            }
          }
        })
      }
      if (!store.getters['instancesState/getSuperUserToggle']) {
        localStorage.removeItem('sUser')
      }
    }
    if (disableHoldingForApp && localStorage.getItem('isApp') === 'true') {
      next()
      return
    }
    if (disableHoldingForTerminal && localStorage.getItem('isTerminal') === 'true') {
      next()
      return
    }
    const superUser = store.getters['instancesState/getSuperUserToggle']
    if ((!hasEventStarted || hasEventEnded) && !superUser) {
      router.push({
        name: 'HoldingPage',
        params: {
          instance,
          event,
          language,
        },
      })
    } else {
      next()
    }
  } else {
    next()
  }
}
