import Fuse from 'fuse.js'
import dayjs from 'dayjs'
import store from '~/store'
import programApi from '../../api/program'
import timezone from 'dayjs/plugin/timezone' // load on demand
import utils from '../../utilities/utils'

dayjs.extend(timezone)

function changeTimezone(time, format) {
  const {config} = store.getters['config/configForKey']('app_config')
    if(time){
      const eventTimezone = config[0]?.EventTimeZone || 'Europe/Berlin'
      const selectedTimezone = localStorage.getItem('selectedTimezone') || eventTimezone
      if(selectedTimezone === eventTimezone) {
       return dayjs(time).format(format ? format :'YYYY-MM-DDTHH:mm:ss')
      } else{
       return dayjs(dayjs.tz(time, eventTimezone)).tz(selectedTimezone).format(format ? format :'YYYY-MM-DDTHH:mm:ss')
      }
  }
  else {
    return time
  }
}

function sortBySessionView(a, b) {
  return b.SessionViewCount - a.SessionViewCount
}

function setPresentationTimezone (session, presentationList) {
  const newPresentationList = presentationList.map((presentation)=>({
    ...presentation,
    SessionStart: changeTimezone(session.StartDateTime),
    SessionEnd: changeTimezone(session.EndDateTime),
    StartDateTime: changeTimezone(presentation.StartDateTime),
    EndDateTime: changeTimezone(presentation.EndDateTime),
    OnDemandAvailability: changeTimezone(presentation.OnDemandAvailability),
    SessionRecordingVideoStartDateTime: changeTimezone(presentation.SessionRecordingVideoStartDateTime),
    SessionRecordingVideoEndDateTime: changeTimezone(presentation.SessionRecordingVideoEndDateTime),
  }))
  return newPresentationList
}
async function addSpeakersInSessionAndPresentation(sessionList) {
  const authorList = await store.getters['pagAuthors/authors']

  const mappedResult = sessionList.map((sessionObject) => {
    const newSession = { ...sessionObject }
    const chairResult = newSession.Chairs.map((chair) => {
      const newChair = { ...chair }
      const speaker = authorList.find((author) => (author.UserId === newChair.UserId))
      if (speaker) {
        newChair.TitleFirstnameSurname = speaker.TitleFirstnameSurname
        newChair.Surname = speaker.Surname
      }
      return newChair
    })

    const presentationResult = newSession.Presentations.map((presentation) => {
      const newPresentation = { ...presentation }
      const speakerResult = newPresentation.Speakers.map((speaker) => {
        const newSpeaker = { ...speaker }
        const presSpeaker = authorList.find((author) => (author.UserId === newSpeaker.UserId))
        if (presSpeaker) {
          newSpeaker.TitleFirstnameSurname = presSpeaker.TitleFirstnameSurname
          newSpeaker.Surname = presSpeaker.Surname
        }
        return newSpeaker
      })
      newPresentation.Speakers = speakerResult
      newPresentation.IsPoster = sessionObject.IsPoster
      newPresentation.SessionStart = sessionObject.StartDateTime
      newPresentation.SessionEnd =  sessionObject.EndDateTime
      newPresentation.SessionGroups = sessionObject.SessionGroups
      newPresentation.SessionTypeName = sessionObject.SessionTypeName
      newPresentation.SessionTags = sessionObject.SessionTags
      newPresentation.SessionTypeGrade = sessionObject.SessionTypeGrade
      newPresentation.IsLiveSession = sessionObject.IsLiveSession
      newPresentation.IsOnDemandSession = sessionObject.IsOnDemandSession
      newPresentation.SessionId = sessionObject.SessionId
      newPresentation.EnableViewCounter = sessionObject.EnableViewCounter
      newPresentation.SessionTracks = sessionObject.SessionTracks
      newPresentation.SessionTargetAudience = sessionObject.SessionTargetAudience
      newPresentation.SessionMethods = sessionObject.SessionMethods
      newPresentation.RoomName = sessionObject.RoomName

      return newPresentation
    })
    newSession.Presentations = presentationResult
    newSession.Chairs = chairResult
    return newSession
  })
  return mappedResult
}

function setSessionTimezone (sessionList) {


  const {config} = store.getters['config/configForKey']('app_config')
  const timezoneSwitch =  config[0]?.timezoneSwitch || false
  const eventTimezone = config[0]?.EventTimeZone || 'Europe/Berlin'
  const selectedTimezone = localStorage.getItem('selectedTimezone') || eventTimezone


  if(timezoneSwitch) {
    if(selectedTimezone === eventTimezone) {
      store.commit('pagSessions/setSessionsTz', sessionList)

    } else{
      const newSessionList = sessionList.map((session)=>({
        ...session,
        StartDateTime: changeTimezone(session.StartDateTime),
        EndDateTime: changeTimezone(session.EndDateTime),
      }))
      store.commit('pagSessions/setSessions', newSessionList)
    }
  } else {
    store.commit('pagSessions/setSessions', sessionList)
  }

}

const pagSessions = {
  namespaced: true,
  state: {
    sessionMetaData: null,
    dayTags: [],
    dayTagsScheduler: [],
    typeTags: [],
    groupTags: [],
    roomTags: [],
    keywordTags: [],
    sessionTags: [],
    presentationTags: [],
    posterDayTags: [],
    posterTypeTags: [],
    posterGroupTags: [],
    posterKeywordTags: [],
    posterPresentationTags: [],
    posterSessionTitleTags: [],
    presentationTypeTags: [],
    posterPresentationTypeTags: [],
    sessionTargetAudience: [],
    sessionMethods: [],
    sessionTracks: [],
    sessions: [],
    sessionsTz: [],
    fetchSessionPromises: [],
    loading: false,
    fuseJs: null,
    availableRooms: [],
    coffeeBreaks: [],
  },
  mutations: {
    setSessions(state, payload) {
      state.sessions = payload
    },
    setSessionsTz(state, payload) {
      state.sessionsTz = payload
    },
    setFetchSessionPromise(state, payload) {
      state.fetchSessionPromises = payload
    },
    setDayTags(state, payload) {
      state.dayTags = payload
    },
    setDayTagsScheduler(state, payload) {
      state.dayTagsScheduler = payload
    },
    setGroupTags(state, payload) {
      state.groupTags = payload
    },
    setRoomTags(state, payload) {
      state.roomTags = payload
    },
    setTypeTags(state, payload) {
      state.typeTags = payload
    },
    setKeywordTags(state, payload) {
      state.keywordTags = payload
    },
    setSessionTags(state, payload) {
      state.sessionTags = payload
    },
    setPresentationTags(state, payload) {
      state.presentationTags = payload
    },
    setPosterDayTags(state, payload) {
      state.posterDayTags = payload
    },
    setPosterSessionTitleCategory(state, payload) {
      state.posterSessionTitleTags = payload
    },
    setPosterGroupTags(state, payload) {
      state.posterGroupTags = payload
    },
    setPosterPresentationTags(state, payload) {
      state.posterPresentationTags = payload
    },
    setPosterTypeTags(state, payload) {
      state.posterTypeTags = payload
    },
    setPosterKeywordTags(state, payload) {
      state.posterKeywordTags = payload
    },
    setAvailableRooms(state, payload) {
      state.availableRooms = payload
    },
    setPresentationTypeTags(state, payload) {
      state.presentationTypeTags = payload
    },
    setPosterPresentationTypeTags(state, payload) {
      state.posterPresentationTypeTags = payload
    },
    setSessionMetaData(state, payload) {
      state.sessionMetaData = payload
    },
    setSessionTracks(state, payload) {
      state.sessionTracks = payload
    },
    setSessionMethods(state, payload) {
      state.sessionMethods = payload
    },
    setSessionTargetAudience(state, payload) {
      state.sessionTargetAudience = payload
    },
    setCoffeeBreaks(state, payload) {
      state.coffeeBreaks = payload
    },
  },
  actions: {
    async loadMetaData({ commit, state }) {
      // if (state.sessionMetaData?.length > 0) {
      //   return state.sessionMetaData
      // }

      const fetchSessionMetaPromises = await programApi.getPagMetaData()
      commit('setSessionMetaData', fetchSessionMetaPromises)
      commit('setSessionTracks', fetchSessionMetaPromises.SessionTracks)
      commit('setSessionMethods', fetchSessionMetaPromises.SessionMethods)
      commit('setSessionTargetAudience', fetchSessionMetaPromises.SessionTargetAudience)
      return fetchSessionMetaPromises
    },
    async loadSessions({ commit, state }) {
      const {config} = store.getters['config/configForKey']('app_config')
      const timezoneSwitch =  config[0]?.timezoneSwitch || false
      const eventTimezone = config[0]?.EventTimeZone || 'Europe/Berlin'
      const selectedTimezone = localStorage.getItem('selectedTimezone') || eventTimezone

      if(timezoneSwitch) {
        if(selectedTimezone === eventTimezone) {
          if (state.sessionsTz?.length > 0) {
            return state.sessionsTz
          }
        } else{
          if (state.sessions?.length > 0) {
            return state.sessions
          }
        }
      } else {
        if (state.sessions?.length > 0) {
          return state.sessions
        }

        if (state.fetchSessionPromises?.length > 0) {
          return state.fetchSessionPromises
        }
      }



      const fetchPromise = await programApi.getSessions().then(async (list) => {
        const newSessions = await addSpeakersInSessionAndPresentation(list)
        setSessionTimezone(newSessions)
      })
      commit('setFetchSessionPromise', fetchPromise)
      return fetchPromise
    },

    async loadRooms({ commit }) {
      const fetchPromise = await programApi.getRooms()
      commit('setAvailableRooms', fetchPromise)
      return fetchPromise
    },

    async loadDays({
      state,
      dispatch,
      commit,
      rootGetters,
    }, payload) {


      const {config} = store.getters['config/configForKey']('app_config')
      const timezoneSwitch =  config[0]?.timezoneSwitch || false
      const eventTimezone = config[0]?.EventTimeZone || 'Europe/Berlin'
      const selectedTimezone = localStorage.getItem('selectedTimezone') || eventTimezone

      let result = []
      if(timezoneSwitch) {
        if(selectedTimezone === eventTimezone) {
          if (!state.sessionsTz.length) {
            await dispatch('loadSessions')
          }
          result = state.sessionsTz
        } else{
          if (!state.sessions.length) {
            await dispatch('loadSessions')
          }
          result = state.sessions
        }
      } else {
        if (!state.sessions.length) {
          await dispatch('loadSessions')
        }
        result = state.sessions

      }

      const dateAppConfig = rootGetters['config/configForKey']('app_config')
      const programConfig = dateAppConfig.config[0].programme[0]
      if (
        payload
        && Object.prototype.hasOwnProperty.call(payload, 'preFilter')
        && payload.preFilter
        && Object.prototype.hasOwnProperty.call(payload, 'filterActive')
        && payload.filterActive
      ) {
        const preFilter = payload.preFilter.find((filter) => filter.name === payload.filterActive)

        if (preFilter && preFilter.sessionGrade) {
          switch (preFilter.sessionGrade) {
            case 'Industry':
              result = result.filter((session) => session.SessionTypeGrade === 'Industry')
              break
            case 'Scientific':
              result = result.filter((session) => session.SessionTypeGrade === 'Scientific')
              break
            default:
              break
          }
        }
        let dayTags = []
        if (result.length > 0) {
          dayTags = [
            ...new Set(result.filter(
            (session) =>  !session.IsPoster && session.StartDateTime && !session.HideOnScheduler)
            .map((session) => session.StartDateTime.substring(0, 10))
          )]
          dayTags = dayTags.map((date) => ({
            name: programConfig.dateformat ? dayjs(String(date))
              .format(programConfig.dateformat) : dayjs(date).format('dddd, DD MMMM'),
            value: date,
          }))
        }
        commit('setDayTagsScheduler', dayTags.sort(utils.sortByDateTags))
        return dayTags.sort(utils.sortByDateTags)
      }

    },


    async search({
      commit,
      state,
      dispatch,
      rootGetters,
    }, payload) {
      const {config} = store.getters['config/configForKey']('app_config')
      const timezoneSwitch =  config[0]?.timezoneSwitch || false
      const eventTimezone = config[0]?.EventTimeZone || 'Europe/Berlin'
      const selectedTimezone = localStorage.getItem('selectedTimezone') || eventTimezone

      let resultUnfiltered = []
      let result = []
      if(timezoneSwitch) {
        if(selectedTimezone === eventTimezone) {
          if (!state.sessionsTz.length) {
            await dispatch('loadSessions')
          }
          result = state.sessionsTz
        } else{
          if (!state.sessions.length) {
            await dispatch('loadSessions')
          }
          result = state.sessions
        }
      } else {
        if (!state.sessions.length) {
          await dispatch('loadSessions')
        }
        result = state.sessions

      }

      if (payload && payload.coffeeBreak && payload.coffeeBreak.length) {
        if (!state.coffeeBreaks.length) {
          commit('setCoffeeBreaks', payload.coffeeBreak)
        }
        result = result.concat(payload.coffeeBreak)
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'segment') && payload.segment) {
        switch (payload.segment) {
          case 'Poster':
            result = result.filter((session) => session.IsPoster)
            break
          case 'Programme':
            result = result.filter((session) => !session.IsPoster)
            break
          default:
            break
        }
      }
      if (
        payload
        && Object.prototype.hasOwnProperty.call(payload, 'preFilter')
        && payload.preFilter
        && Object.prototype.hasOwnProperty.call(payload, 'filterActive')
        && payload.filterActive
      ) {
        const preFilter = payload.preFilter.find((filter) => filter.name === payload.filterActive)

        if (preFilter && preFilter.sessionGrade) {
          switch (preFilter.sessionGrade) {
            case 'Industry':
              result = result.filter((session) => session.SessionTypeGrade === 'Industry')
              resultUnfiltered = resultUnfiltered.filter((session) => session.SessionTypeGrade === 'Industry')
              break
            case 'Scientific':
              result = result.filter((session) => session.SessionTypeGrade === 'Scientific')
              resultUnfiltered = resultUnfiltered.filter((session) => session.SessionTypeGrade === 'Scientific')

              break
            default:
              break
          }
        }
        if (preFilter && preFilter.sessionId?.length > 0) {
          result = result.filter((session) => preFilter.sessionId.includes(session.SessionId))
        }
        if (preFilter && preFilter.date?.length > 0) {
          result = result.filter((session) => preFilter.date.includes(session.StartDateTime.substring(0, 10)))
        }
        if (preFilter && preFilter.sessionGroups?.length > 0) {
          result = result.filter((session) => preFilter.sessionGroups
            .some((preItem) => session.SessionGroups.map((item) => item.Name).includes(preItem)))
        }
        if (preFilter && preFilter.sessionTypes?.length > 0) {
          result = result.filter((session) => preFilter.sessionTypes.includes(session.SessionTypeName))
        }
        if (preFilter && preFilter.sessionTags?.length > 0) {
          result = result.filter((session) => preFilter.sessionTags.includes(session.SessionTags))
        }
        if (preFilter && preFilter.rooms?.length > 0) {
          result = result.filter((session) => preFilter.rooms.includes(session.RoomName))
        }
        let ondemandResult = []
        if (preFilter && preFilter.isOnDemandSession) {
          ondemandResult = result.filter((session) => session.IsOnDemandSession)
        }

        let liveResult = []
        if (preFilter && preFilter.isLiveSession) {
          liveResult = result.filter((session) => session.IsLiveSession)
        }

        if (ondemandResult.length > 0 || liveResult.length > 0) {
          result = [...new Set(ondemandResult.concat(...liveResult))]
        }

        if (preFilter && preFilter.OnlyLiveSessions) {
          result = result.filter((session) => session.IsLiveSession && !session.IsOnDemandSession)
        }

        if (preFilter && preFilter.OnlyOndemandSessions) {
          result = result.filter((session) => (!session.IsLiveSession && session.IsOnDemandSession))
        }
        if (
          payload
          && Object.prototype.hasOwnProperty.call(payload, 'industry')
        ) {
          if (!payload.industry) {
            result = result.filter((session) => session.SessionTypeGrade === 'Scientific')
          }
        }
        if (payload && Object.prototype.hasOwnProperty.call(payload, 'highlights') && payload?.highlights) {
          result = result.filter((session) => session.Highlight)
        }

        if (payload.segment) {

          const dateAppConfig = rootGetters['config/configForKey']('app_config')
          const programConfig = dateAppConfig.config[0].programme[0]
          switch (payload.segment) {
            case 'Poster': {
              let posterDayTags = []

              if (result.length > 0) {
                posterDayTags = [
                  ...new Set(result.filter(
                  (session) => session.StartDateTime)
                  .map((session) => session.StartDateTime.substring(0, 10))
                )]
                posterDayTags = posterDayTags.map((date) => ({
                  name:  programConfig.dateformat ? dayjs(String(date))
                    .format(programConfig.dateformat) : dayjs(date).format('dddd, DD MMMM'),
                  value: date,
                }))
              }
              commit('setPosterDayTags', posterDayTags.sort(utils.sortByDateTags))
              break
            }
            case 'Programme': {
              let dayTags = []
              let dayTagsScheduler = []

              if (result.length > 0) {

                dayTagsScheduler = [
                  ...new Set(result.filter(
                  (session) =>  !session.IsPoster && session.StartDateTime && !session.HideOnScheduler)
                  .map((session) => session.StartDateTime.substring(0, 10))
                )]

                dayTags = [
                  ...new Set(result.filter(
                  (session) =>  !session.IsPoster && session.StartDateTime)
                  .map((session) => session.StartDateTime.substring(0, 10))
                )]

                dayTags = dayTags.map((date) => {
                  //remove this after ers2022
                  const name = (localStorage.getItem('pag-event') === 'ers2022' && date === '2022-08-22') ? 'Pre-Congress Content' : programConfig.dateformat ? dayjs(String(date))
                  .format(programConfig.dateformat) : dayjs(date).format('dddd, DD MMMM')
                  return {
                    name,
                    value: date,
                  }
                })

                dayTagsScheduler = dayTagsScheduler.map((date) => ({
                  name:  programConfig.dateformat ? dayjs(String(date))
                    .format(programConfig.dateformat) : dayjs(date).format('dddd, DD MMMM'),
                  value: date,
                }))

              }
              commit('setDayTags', dayTags.sort(utils.sortByDateTags))
              commit('setDayTagsScheduler', dayTagsScheduler.sort(utils.sortByDateTags))

              break
            }
            default:
              break
          }
        }



        if (payload.segment) {
          switch (payload.segment) {
            case 'Poster': {
              let posterTypeTags = []
              let posterGroupTags = []
              let posterKeywordTags = []
              let posterPresentationTags = []
              let posterSessionTitleTags = []
              let posterPresentationTypeTags = []

              if (result.length > 0) {
                posterKeywordTags = [...new Set(result.reduce(
                  (newArr, session) => [...newArr, ...session.Presentations || {}], []
                ).reduce((newArr, presentation) => [...newArr, ...presentation.Keywords || {}], []))].filter((item) => item)
                posterPresentationTags = [...new Set(result.reduce(
                  (newArr, session) => [...newArr, ...session.Presentations || {}], []
                ).reduce((newArr, presentation) => [...newArr, ...presentation.PresentationTags || ''], []))]
                  .filter((item) => item)
                posterTypeTags = [...new Set(result.map((session) => session.SessionTypeName))].filter((item) => item)
                posterPresentationTypeTags = [...new Set(result
                  .reduce((newArr, session) => [...newArr, ...session.Presentations || {}], [])
                  .map((presentation) => presentation.PresentationTypeName ))].filter((item) => item)
                posterGroupTags = [...new Set(result.reduce((newArr, session) => {
                  const tagsArr = session.SessionGroups.map((group) => group.Name)
                  return [...newArr, ...tagsArr || ''].filter((item) => item)
                }, []))]
                posterSessionTitleTags = [...new Set(result.map((session) => session.TextWithFormat))].filter((item) => item)

              }

              commit('setPosterGroupTags', posterGroupTags.sort(utils.sortArrayByName))
              commit('setPosterTypeTags', posterTypeTags.sort(utils.sortArrayByName))
              commit('setPosterKeywordTags', posterKeywordTags.sort(utils.sortArrayByName))
              commit('setPosterPresentationTags', posterPresentationTags.sort(utils.sortArrayByName))
              commit('setPosterSessionTitleCategory', posterSessionTitleTags.sort(utils.sortArrayByName))
              commit('setPosterPresentationTypeTags', posterPresentationTypeTags.sort(utils.sortArrayByName))

              break
            }
            case 'Programme': {
              let typeTags = []
              let groupTags = []
              let roomTags = []
              let keywordTags = []
              let sessionTags = []
              let presentationTags = []
              let presentationTypeTags = []

              if (result.length > 0) {
                keywordTags = [...new Set(result.reduce((newArr, session) => [...newArr, ...session.Presentations || {}], [])
                  .reduce((newArr, presentation) => [...newArr, ...presentation.Keywords || {}], []))]
                typeTags = [...new Set(result.map((session) => session.SessionTypeName))].filter((type) => type)
                groupTags = [...new Set(result.reduce((newArr, session) => {
                  const tagsArr = session.SessionGroups.map((group) => group.Name)
                  return [...newArr, ...tagsArr || '']
                }, []))].filter((item) => item)
                presentationTags = [...new Set(result.reduce(
                  (newArr, session) => [...newArr, ...session.Presentations || {}], []
                ).reduce((newArr, presentation) => [...newArr, ...presentation.PresentationTags || ''], []))]
                  .filter((item) => item)
                presentationTypeTags = [...new Set(result
                  .reduce((newArr, session) => [...newArr, ...session.Presentations || {}], [])
                  .map((presentation) => presentation.PresentationTypeName ))].filter((item) => item)

                roomTags = [...new Set(result.map((session) => session.RoomName))].filter((item) => item).map((room) => {
                  const availableRoom = state.availableRooms.find((roomx) => roomx.Name === room)
                  return { Name: room, Sort: availableRoom.Sort}
                })
                sessionTags = [...new Set(result.reduce((newArr, session) => {
                  const tagsArr = session.SessionTags?.map((group) => group)
                  return [...newArr, ...tagsArr || '']
                }, []))].filter((item) => item)
              }

              commit('setKeywordTags', keywordTags.sort(utils.sortArrayByName))
              commit('setTypeTags', typeTags.sort(utils.sortArrayByName))
              commit('setGroupTags', groupTags.sort(utils.sortArrayByName))
              commit('setRoomTags', roomTags.sort(utils.sortByRoomSort))
              commit('setSessionTags', sessionTags.sort(utils.sortArrayByName))
              commit('setPresentationTags', presentationTags.sort(utils.sortArrayByName))
              commit('setPresentationTypeTags', presentationTypeTags.sort(utils.sortArrayByName))

              break
            }
            default:
              break
          }
        }
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'dayTags') && payload?.dayTags.length > 0) {

        const dayTagArr = payload.dayTags.map((item) => item.value)
        result = result.filter(
          (session) => dayTagArr.includes((session.StartDateTime || '').substring(0, 10))
        )
        resultUnfiltered = resultUnfiltered.filter(
          (session) => dayTagArr.includes((session.StartDateTime || '').substring(0, 10))
        )
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'groupTags') && payload?.groupTags.length > 0) {
        result = result.filter((session) => payload.groupTags.some(
          (group) => session.SessionGroups.map((item) => item.Name).includes(group))
        )
      }

    if (payload && Object.prototype.hasOwnProperty.call(payload, 'sessionTags') && payload?.sessionTags.length > 0) {
      result = result.filter((session) =>
        payload.sessionTags.some((tag) => session.SessionTags?.map((item) => item.toLowerCase()).includes(tag.toLowerCase()))
      )
    }

      // let typeResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'typeTags') && payload?.typeTags.length > 0) {
        result = result.filter((item) => payload.typeTags.includes(item.SessionTypeName))
      }
      // let roomResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'roomTags') && payload?.roomTags.length > 0) {
        result = result.filter((item) => payload.roomTags.some((rItem) => rItem.Name === item.RoomName))
      }

      // let keywordResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'keywordTags') && payload?.keywordTags.length > 0) {
        result = result.filter((session) => [...new Set(
          [].concat(...session.Presentations.map((presentation) => presentation.Keywords)),
        )].some((keyword) => payload.keywordTags.includes(keyword)))
      }

      // let sessionTracksResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'sessionTracks') && payload?.sessionTracks.length > 0) {
        result = result.filter((session) =>
          payload.sessionTracks.some((tag) => session.SessionTracks?.map((item) => item.toLowerCase()).includes(tag.toLowerCase()))
        )
      }
      // let sessionMethodsResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'sessionMethods') && payload?.sessionMethods.length > 0) {
        result = result.filter((session) =>
          payload.sessionMethods.some((tag) => session.SessionMethods?.map((item) => item.toLowerCase()).includes(tag.toLowerCase()))
        )
      }
      // let sessionTargetAudienceResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'sessionTargetAudience') && payload?.sessionTargetAudience.length > 0) {
        result = result.filter((session) =>
          payload.sessionTargetAudience.some((tag) => session.SessionTargetAudience?.map((item) => item.toLowerCase()).includes(tag.toLowerCase()))
        )
      }

      // if (roomResult.length > 0 || typeResult.length > 0 || keywordResult.length > 0 || sessionTracksResult.length > 0 || sessionMethodsResult.length > 0 || sessionTargetAudienceResult.length > 0) {
      //   result = [...new Set(typeResult.concat(...roomResult).concat(...keywordResult).concat(...sessionTracksResult).concat(...sessionMethodsResult).concat(...sessionTargetAudienceResult))]
      // }

      if (payload && payload.text) {
        const options = {
          keys: [
            'RoomName',
            'SessionId',
            'TextWithFormat',
            'Chairs.TitleFirstnameSurname',
            'SessionNumber',
          ],
          maxPatternLength: 32,
          minMatchCharLength: 3,
          threshold: 0.0,
          ignoreLocation: true,
        }

        const fuse = new Fuse(result, options)
        const fuseResult = fuse.search(payload.text)
        const tempResult = []
        for (let i = 0, len = fuseResult.length; i < len; i += 1) { // TODO: check if for is really needed
          tempResult.push(fuseResult[i].item)
        }
        result = tempResult
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'sortBy') && payload.sortBy) {
        switch (payload.sortBy) {
          case 'alphabetical':
            result.sort(utils.sortByName)
            break
          case 'date':
            result.sort(utils.sortByDate)
            break
          case 'random':
            utils.shuffleArray(result)
            break
          case 'room':
            result.sort(utils.sortByRoom)
            break
          case 'sessionnumber':
            result.sort(utils.sortBySessionNumber())
            break
          case 'views':
            result.sort(sortBySessionView)
            break
          case 'sortbylastname':
            result.sort(utils.sortByChairLastName)
            break
          case 'sessiontype':
            // eslint-disable-next-line no-case-declarations
            const sessionTypeList = payload.customListSort?.find(x => x.sessionTypes !== undefined)
            // eslint-disable-next-line no-case-declarations
            let sessionTypeOrder = sessionTypeList?.sessionTypes
            if (sessionTypeOrder) {
              const types = [...new Set(result.map(a => a.SessionTypeName))]
              sessionTypeOrder = [...new Set([...sessionTypeOrder, ...types])]
              const map = new Map()
              sessionTypeOrder.forEach((x, i) => map.set(x, i))
              result.sort((x, y) => map.get(x.SessionTypeName) - map.get(y.SessionTypeName))
            } else {
              result.sort(utils.sortBySessionType)
            }
            break
          default:
            break
        }
      }
      return result
    },

  },
  getters: {
    sessions(state) {
      return state.sessions
    },
    sessionsTz(state) {
      return state.sessionsTz
    },
    dayTags(state) {
      return state.dayTags
    },
    dayTagsScheduler(state) {
      return state.dayTagsScheduler
    },
    typeTags(state) {
      return state.typeTags
    },
    availableRooms(state) {
      return state.availableRooms
    },
    groupTags(state) {
      return state.groupTags
    },
    roomTags(state) {
      return state.roomTags
    },
    keywordTags(state) {
      return state.keywordTags
    },
    sessionTags(state) {
      return state.sessionTags
    },
    posterDayTags(state) {
      return state.posterDayTags
    },
    posterTypeTags(state) {
      return state.posterTypeTags
    },
    posterGroupTags(state) {
      return state.posterGroupTags
    },
    posterKeywordTags(state) {
      return state.posterKeywordTags
    },
    posterPresentationTags(state) {
      return state.posterPresentationTags
    },
    presentationTags(state) {
      return state.presentationTags
    },
    posterSessionTitleTags(state) {
      return state.posterSessionTitleTags
    },
    presentationTypeTags(state) {
      return state.presentationTypeTags
    },
    posterPresentationTypeTags(state) {
      return state.posterPresentationTypeTags
    },
    sessionTargetAudience(state) {
      return state.sessionTargetAudience
    },
    sessionMethods(state) {
      return state.sessionMethods
    },
    sessionTracks(state) {
      return state.sessionTracks
    },
    sessionMetaData(state) {
      return state.sessionMetaData
    },
  },
}
export default pagSessions
