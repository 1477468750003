import Fuse from 'fuse.js'
import programApi from '../../api/program'

function sortByName(a, b) {
  return (a.TitleRaw || '').localeCompare(b.TitleRaw || '', undefined, { numeric: true, sensitivity: 'base' })
}

function sortByAbstractId(a, b) {
  return (a.Number || '').localeCompare(b.Number || '', undefined, { numeric: true, sensitivity: 'base' })
}

const pagAbstracts = {
  namespaced: true,
  state: {
    type: [],
    category: [],
    abstracts: [],
    fetchSessionPromises: [],
    loading: false,
    fuseJs: null,
  },
  mutations: {
    setAbstracts(state, payload) {
      state.abstracts = payload
    },
    setFetchAbstractPromise(state, payload) {
      state.fetchAbstractPromises = payload
    },
    setCategoryTags(state, payload) {
      state.category = payload
    },
    setTypeTags(state, payload) {
      state.type = payload
    },
  },
  actions: {
    async loadAbstracts({ commit, state }) {
      if (state.abstracts.length > 0) {
        return state.abstracts
      }
      if (state.fetchAbstractPromises?.length > 0) {
        return state.fetchAbstractPromises
      }
      const fetchPromise = await programApi.getAbstracts().then(async (list) => {
        commit('setAbstracts', list)
      })
      commit('setFetchAbstractPromise', fetchPromise)
      return fetchPromise
    },

    async search({
      commit,
      state,
      dispatch,
    }, payload) {
      if (!state.abstracts.length) {
        await dispatch('loadAbstracts')
      }
      let result = state.abstracts
      if (
        payload
        && Object.prototype.hasOwnProperty.call(payload, 'preFilter')
        && payload.preFilter
        && Object.prototype.hasOwnProperty.call(payload, 'filterActive')
        && payload.filterActive
      ) {
        const preFilter = payload.preFilter.find((filter) => filter.name === payload.filterActive)

        if (preFilter.abstractId?.length > 0) {
          result = result.filter((abstract) => preFilter.abstractId.includes(abstract.Id))
        }
        if (preFilter.abstractTypes?.length > 0) {
          result = result.filter((abstract) => preFilter.abstractTypes.includes(abstract.TypeName))
        }
        if (preFilter.abstractCategory?.length > 0) {
          result = result.filter((abstract) => preFilter.abstractCategory.includes(abstract.Category))
        }
      }

      let typeTags = []
      let categoryTags = []
      if (result.length > 0) {
        typeTags = [...new Set(result.map((abstract) => abstract.TypeName))]
        categoryTags = [...new Set(result.map((abstract) => abstract.Category))]
      }

      commit('setTypeTags', typeTags)
      commit('setCategoryTags', categoryTags)

      let typeResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'typeTags') && payload?.typeTags.length > 0) {
        typeResult = result.filter((item) => payload.typeTags.includes(item.TypeName))
      }

      let categoryResult = []
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'categoryTags') && payload?.categoryTags.length > 0) {
        categoryResult = result.filter((item) => payload.categoryTags.includes(item.Category))
      }

      if (typeResult.length > 0 || categoryResult.length > 0) {
        result = [...new Set(typeResult.concat(...categoryResult))]
      }

      if (payload && payload.text) {
        const options = {
          keys: [
            'Title',
          ],
          maxPatternLength: 32,
          minMatchCharLength: 3,
          threshold: 0,
          ignoreLocation: true,
        }

        const fuse = new Fuse(result, options)
        const fuseResult = fuse.search(payload.text)
        const tempResult = []
        for (let i = 0, len = fuseResult.length; i < len; i += 1) { // TODO: check if for is really needed
          tempResult.push(fuseResult[i].item)
        }
        result = tempResult
      }

      if (payload && Object.prototype.hasOwnProperty.call(payload, 'sortBy') && payload.sortBy) {
        switch (payload.sortBy) {
          case 'alphabetical':
            result.sort(sortByName)
            break
          case 'abstractnumber':
            result.sort(sortByAbstractId)
            break
          default:
            break
        }
      }
      return result
    },

  },
  getters: {
    abstracts(state) {
      return state.abstract
    },
    categoryTags(state) {
      return state.category
    },
    typeTags(state) {
      return state.type
    },
  },
}

export default pagAbstracts
